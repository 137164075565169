<template>
  <div>
    <p>{{ line1 }}</p>
    <p>{{ line2 }}</p>
    <p>{{ dependentLocality }}</p>
    <p>
      {{ city ? `${city},` : '' }}
      {{ zip ? `${zip},` : '' }}
      {{ administrativeArea ? `${administrativeArea},` : '' }}
      {{ countryCodeToFullName(country) }}
    </p>
  </div>
</template>

<script setup>
import useGeoloc from '@shared/hooks/geoloc'

defineProps({
  // Address main line
  line1: {
    type: String,
    default: null,
  },
  // Address secondary line
  line2: {
    type: String,
    default: null,
  },
  // Address zip (post) code
  zip: {
    type: String,
    default: null,
  },
  // Address city
  city: {
    type: String,
    default: null,
  },
  // Address dependent locality
  dependentLocality: {
    type: String,
    default: null,
  },
  // Address administrative area
  administrativeArea: {
    type: String,
    default: null,
  },
  // Address country
  country: {
    type: String,
    default: null,
  },
})

const { countryCodeToFullName } = useGeoloc()
</script>
