<template>
  <div class="flex">
    <!-- Logo -->
    <router-link
      v-slot="{ navigate }"
      custom
      :to="{ name: 'show-partners', params: { id: resource.id } }"
    >
      <div
        :class="`w-1/3 bg-cover bg-center ${withLink ? 'cursor-pointer' : ''}`"
        :style="`background-image: url(${mainImageUrl});`"
        @click="withLink ? navigate() : null"
      />
    </router-link>

    <div class="w-2/3 p-3">
      <!-- Details -->
      <router-link
        v-slot="{ navigate }"
        custom
        :to="{ name: 'show-partners', params: { id: resource.id } }"
      >
        <div
          :class="`mb-3 ${withLink ? 'cursor-pointer' : ''}`"
          @click="withLink ? navigate() : null"
        >
          <div class="flex items-center justify-between">
            <span class="font-bold text-sm truncate">
              {{ resource.attributes.name }}
            </span>
          </div>

          <div
            v-if="resource.attributes.description"
            class="text-gray-500 text-xs mt-2"
          >
            <div class="flex items-center truncate">
              <p>
                {{ resource.attributes.description }}
              </p>
            </div>
          </div>

          <div
            v-if="resource.attributes.contact"
            class="text-xs mt-2"
          >
            <div class="flex items-center">
              <p>
                {{ resource.attributes.contact }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import usePartner from '@shared/hooks/resources/partner'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Add a link to resource show page or not
  withLink: {
    type: Boolean,
    default: true,
  },
})

// ---------- LOGO ----------

const {
  getLogoUrl,
} = usePartner(props)

const mainImageUrl = computed(() => (
  getLogoUrl('sm')
))
</script>
