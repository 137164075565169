import { computed } from 'vue'

export default function usePartner(props) {
  /**
   * Return logo URL
   *
   * @param version
   * @return {*|string}
   */
  function getLogoUrl(version = 'sm') {
    return props.resource.relationships?.logo?.attributes?.urls?.[version]
      ?? '/images/shared/placeholders/partners.png'
  }

  // ---------- BORDER ----------

  const imageBorder = computed(() => (
    'bg-contain rounded-2lg bg-no-repeat bg-center bg-origin-padding border-8 border-transparent'
  ))

  return {
    getLogoUrl,
    imageBorder,
  }
}
