<template>
  <partner-show
    v-if="partner && !loading"
    :resource="partner"
  />

  <partner-show-empty
    v-else-if="loading"
  />
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { fetchPartner as fetchApiPartner } from '@shared/http/api'
import PartnerShow from '@app/components/resources/partner/PartnerShow.vue'
import PartnerShowEmpty from '@app/components/resources/partner/PartnerShowEmpty.vue'

const route = useRoute()

const partner = ref(null)
const loading = ref(false)

function fetchPartner() {
  loading.value = true
  const { id } = route.params

  const params = {
    'mode': 'app',
  }

  fetchApiPartner(id, params)
    .then((response) => {
      partner.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => (
  fetchPartner()
))
</script>
