<template>
  <div class="-mx-4 mb-4">
    <div
      v-if="mainImageUrl"
      :style="`background-image: url(${mainImageUrl});`"
      class="bg-center bg-cover w-full h-60 relative"
    />

    <div
      v-else
      class="bg-center bg-cover w-full h-60"
      style="background-image: url('/images/shared/placeholders/partners.png');"
    />
  </div>

  <!-- Main information -->
  <app-card-item
    class="p-3 relative -top-10 -mb-6"
  >
    <div class="flex items-center justify-between">
      <span class="font-bold text-lg mr-1">
        {{ resource.attributes.name }}
      </span>
    </div>

    <div class="text-gray-500 text-xs">
      <div
        v-if="resource.attributes.contact"
        class="flex flex-wrap"
      >
        <div
          class="bg-gray-500 text-white py-0.5 px-2 w-fit rounded-full mr-1 last:mr-0 my-0.5"
        >
          {{ resource.attributes.contact }}
        </div>
      </div>

      <!-- Link -->
      <a
        v-if="resource.attributes.link"
        :href="resource.attributes.link"
        target="_blank"
        class="app-link block mt-3"
      >
        {{ t('common.actions.see_website') }}
      </a>
    </div>
  </app-card-item>

  <p
    v-if="resource.attributes.description"
    class="mb-5 text-xs"
  >
    {{ resource.attributes.description }}
  </p>

  <!-- Address -->
  <div class="mb-5 text-xs">
    <partner-address
      v-if="resource.relationships.address"
      :resource="resource"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import PartnerAddress from '@shared/components/resources/partner/PartnerAddress.vue'
import usePartner from '@shared/hooks/resources/partner'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

// ---------- LOGO ----------

const {
  getLogoUrl,
} = usePartner(props)

const mainImageUrl = computed(() => (
  getLogoUrl('md')
))
</script>
