<template>
  <partner-list
    :title="t('partners.customers.title')"
    type="customer"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import PartnerList from '@app/components/resources/partner/PartnerList.vue'

const { t } = useI18n()
</script>
